@import '../../App.scss';

.bi {
  font-size: 2rem;
  gap: 5rem;
}

.contact-form {
  margin-top: 2rem;




  .form-group {
    margin-bottom: 1rem;

    label {
      font-weight: bold;
    }

    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: 1rem;
    }

    textarea {
      resize: vertical;
      min-height: 100px;
    }
  }

  button[type="submit"] {
    background-color: $primary-color;
    color: #fff;
    border: none;
    padding: .5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.1rem;
  }

  button[type="submit"]:hover {
    background-color: $secondary-color;
  }
}

@media (max-width: 768) {
  .contact-form {
    width: 100vw;
    padding-top: 0rem !important;
    margin-top: 0rem !important;

  }

}