@import '../../App.scss';

.view-resume {
  padding: 1rem;
  display: flex;
  gap: .5rem;
  align-items: center;
  justify-content: center;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;

  .modal-content {
    background-color: white;
    padding: 1.2rem;
    width: 80%;
    max-width: 50rem;
    position: relative;

    .close-button {
      position: absolute;
      top: 10px;
      right: 5px;
      font-size: 1rem;
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.nav-link {
  cursor: pointer;
}


@media (max-width: 576px) {
  .vertical-timeline {

    .view-resume {
      font-size: 14px;
      padding: .5rem 1rem;
    }
  }

}