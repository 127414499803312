@import '../../App.scss';


.navbar {
  padding: 1rem 1rem 5rem 1rem;

  @media (max-width: 768px) {
    padding: 1rem 0rem;

  }
}

.navbar-nav .nav-link {
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  user-select: none;
  margin: 0 auto;
  text-emphasis: none;
}

.navbar-nav .nav-link:hover {
  color: $accent-color !important;
  transition: transform .8s, box-shadow 0.3s;
  transform: translateY(-5px);
}

.navbar-brand {
  font-size: 2.5rem !important;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 1.75rem !important;

  }
}